import React from "react";
import { Layout } from "../components/layout";
import { graphql } from "gatsby";
import { useJeunessePage } from "../services/firestore";
import { Highlight, HighlightedImages } from '../components/Highlight'
import { Quotes } from '../components/Quote'

const JeunessePage = ({ data }) => {
  const page = useJeunessePage(data.allJeunessePages.nodes[0]);
  const heroBanner = {
    kind: page.heroBannerType,
    image: page.heroBannerImage,
    video: page.heroBannerVideo,
    buttons: page.buttons,
  };

  return (
    <Layout heroBanner={heroBanner} pageTitle={"Église Parole de vie | Connexion"}>
      {page && (
        <Highlight
          title={page.title}
          subtitle={page.subtitle}
          text={page.highlight}
        ></Highlight>
      )}

      {page && <HighlightedImages size="L" images={page.images} />}

      {page && <Quotes quotes={page.quotes} />}
    </Layout>
  );
};

export const query = graphql`
  query {
    allJeunessePages {
      nodes {
        title
        highlight
        heroBannerType
        buttons {
          text
          url
          subject
          default
        }
        heroBannerImage {
          src
        }
        images {
          image {
            image
            src
          }
        }
        quotes {
          text
          author
        }
      }
    }
  }
`;

export default JeunessePage;
